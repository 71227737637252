import React from 'react';
import './Testimonials.css';  

import { Heading } from '../../../components';
import { testiContent } from '../../../constants';

import { GoQuote } from 'react-icons/go'; 

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from 'swiper'; 
 
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles   
import 'swiper/css';  
import 'swiper/css/pagination'; 
import 'swiper/css/navigation';


const Testimonials = () => { 
  return (
    <> 
      <section className='testimonial'>

        <Heading title={'testimonials'} span={'what customers say'} />

        <div className='testimonial-slider'>
        <Swiper 
        // install Swiper modules
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={150}
        loop={true}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        navigation
        pagination={{ clickable: true }}
        >
          {
            testiContent.map((testimonial) => {
              return (
                <SwiperSlide className='testi-item' key={testimonial.id}>

                  <div className='content'>
                    <div className='comment'>
                      <GoQuote className='quote-start'/>
                      <GoQuote className='quote-end'/>
                      <p>{testimonial.content}</p>
                    </div>
                    <h3>{testimonial.name}</h3>
                    <h5>{testimonial.title}</h5>
                  </div>
                  
                  <div class="image">
                    <img src={testimonial.image} alt='testimonial-pic' />
                  </div>

                </SwiperSlide>
              )
            })
          }

        </Swiper>
        </div>
        
      </section>
    </>

  )
} 

export default Testimonials