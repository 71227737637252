import { images } from '.';

export const herbal = [
    {
        id: 1,
        image: images.herbalMenu1,  
        name: 'Chamomile Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 2,
        image: images.herbalMenu2, 
        name: 'Peppermint Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 3,
        image: images.herbalMenu3, 
        name: 'Ginger Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 4,
        image: images.herbalMenu4, 
        name: 'Hibiscus Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 5,
        image: images.herbalMenu5, 
        name: 'Rooibos Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 6,
        image: images.herbalMenu6, 
        name: 'Sage Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 7,
        image: images.herbalMenu7, 
        name: 'Lemon Balm Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 8,
        image: images.herbalMenu8, 
        name: 'Rose Hip Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
];

export const chai = [
    {
        id: 1,
        image: images.chaiMenu1,  
        name: 'Cardamom Chai',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 2,
        image: images.chaiMenu2, 
        name: 'cinnamon chai',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 3,
        image: images.chaiMenu3, 
        name: 'Chai Tea Latte',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 4,
        image: images.chaiMenu4, 
        name: 'matka chai',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 5,
        image: images.chaiMenu5, 
        name: 'Tulsi Chai',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 6,
        image: images.chaiMenu6, 
        name: 'Vanilla Chai',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 7,
        image: images.chaiMenu7, 
        name: 'Chai espresso',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 8,
        image: images.chaiMenu8, 
        name: 'Ginger Chai',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
];


export const green = [
    {
        id: 1,
        image: images.greenMenu1, 
        name: 'Matcha Green Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 2,
        image: images.greenMenu2, 
        name: 'Sencha Green Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 3,
        image: images.greenMenu3, 
        name: 'Shincha Green Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 4,
        image: images.greenMenu4, 
        name: 'Jasmine Green Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 5,
        image: images.greenMenu5, 
        name: 'Longjing Green Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 6,
        image: images.greenMenu6, 
        name: 'Gyokuro Green Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 7,
        image: images.greenMenu7, 
        name: 'Mint Green tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 8,
        image: images.greenMenu8, 
        name: 'gunpowder tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
];


export const black = [
    {
        id: 1,
        image: images.blackMenu1, 
        name: 'Masala Chai',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 2,
        image: images.blackMenu2, 
        name: 'Chocolate Chai',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 3,
        image: images.blackMenu3, 
        name: 'Yunnan Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 4,
        image: images.blackMenu4, 
        name: 'Earl Gray Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 5,
        image: images.blackMenu5, 
        name: 'Turkish Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 6,
        image: images.blackMenu6, 
        name: 'English Breakfast Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 7,
        image: images.blackMenu7, 
        name: 'Nutty Black Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
    {
        id: 8,
        image: images.blackMenu8, 
        name: 'Assam Black Tea',
        price: '20',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
    },
];

