import React from 'react';
import './Footer.css';

import { Logo } from '../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai'; 
import { BsMapFill, BsArrowRight } from 'react-icons/bs';

import { Link } from 'react-router-dom';


   
const Footer = () => {  
  
  return (
    <footer className='footer'>

      <div className='box-container'>
        
        <div class="footer-item">
          <Logo />
          <p>
          Tea is good for the mind, body and soul.
          If it’s tea you’re looking for, we are the place to be.
          Our tea is so good you will want to drink it everyday
          </p>
        </div> 

        <div class="footer-item">
          <h2>opening hours</h2>
          <div class="timing">
            <h3>monday - friday</h3>
            <p>8am - 6pm</p>
          </div>
          <div class="timing">
            <h3>saturday - sunday</h3>
            <p>10am - 4pm</p>
          </div>
        </div>

        <div className='footer-item'>
          <h2>quick links</h2>
          <div className='info links'>
            <p><BsArrowRight className='icon' /><Link to='/'>home</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/about-us'>about</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/blog-grid'>blog</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/menu'>menu</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/gallery'>gallery</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/shop-grid'>shop</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/contact-us'>contact</Link></p>
          </div>
        </div> 

        <div className='footer-item'>
          <h2>contact info</h2> 
          <div className='info'>
            <p><AiTwotonePhone className='icon' /><span>+111-222-333</span></p>
            <p><AiTwotonePhone className='icon' /><span>+123-456-789</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">abc@gmail.com</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">xyz@gmail.com</span></p>
            <p><BsMapFill className='icon' /><span>karachi, pakistan</span></p>
          </div>
          <div class="social">
            <a href="/#"><FaFacebookF className='icon' /></a>
            <a href="/#"><FaTwitter className='icon' /></a>
            <a href="/#"><FaInstagram className='icon' /></a>
            <a href="/#"><FaLinkedin className='icon' /></a>
          </div>
        </div>

      </div>

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;