import React from 'react';
import './ScrollTop.css';

import ScrollToTop from "react-scroll-to-top";



const ScrollTop = () => {
  return (
    <ScrollToTop className='scroll-top' /> 
  )
} 

export default ScrollTop; 