import { images } from '.';

export const galleryContent = [
    {
        id: 1, 
        image: images.gallery1,
        title: 'Hibiscus Tea',
        category: 'Herbal Tea',
        tag: 'Herbal',
        link: '#'
    },
    {
        id: 2,
        image: images.gallery2,
        title: 'Peppermint Tea',
        category: 'Herbal Tea',
        tag: 'Herbal',
        link: '#'
    },
    {
        id: 3,
        image: images.gallery3,
        title: 'Chamomile Tea',
        category: 'Herbal Tea',
        tag: 'Herbal',
        link: '#',
    },
    {
        id: 4,
        image: images.gallery4,
        title: 'Chai Latte',
        category: 'Chai Tea',
        tag: 'Chai',
        link: '#'
    },
    {
        id: 5,
        image: images.gallery5,
        title: 'Cardamom Tea',
        category: 'Chai Tea',
        tag: 'Chai',
        link: '#'
    },
    {
        id: 6,
        image: images.gallery6,
        title: 'Chocolate Chai',
        category: 'Black Tea',
        tag: 'Black',
        link: '#'
    },
    {
        id: 7,
        image: images.gallery7,
        title: 'Earl Gray Tea',
        category: 'Black Tea',
        tag: 'Black',
        link: '#'
    },
    {
        id: 8,
        image: images.gallery8,
        title: 'Matcha',
        category: 'Green Tea',
        tag: 'Green',
        link: '#'
    },
    {
        id: 9,
        image: images.gallery9,
        title: 'Sencha',
        category: 'Green Tea',
        tag: 'Green',
        link: '#'
    },
];


