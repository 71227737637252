import { images } from '../constants';

const testimonials = [
    { 
        id: 1,
        image: images.testimonial1,
        name: 'Allen Hill',
        title: 'customer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    {
        id: 2,
        image: images.testimonial2,
        name: 'Liz Amos',
        title: 'customer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    {  
        id: 3,
        image: images.testimonial3,
        name: 'Tom Emmett',
        title: 'customer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
        
    },
    {
        id: 4,
        image: images.testimonial4,
        name: 'Janice Parker',
        title: 'customer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
];

export default testimonials;