import { images } from '../constants';

export const categories = [ 
    {
        id: 1,
        title: 'herbal tea',
        quantity: '5',
    },
    {
        id: 2,
        title: 'chai tea',
        quantity: '2',
    }, 
    {
        id: 3, 
        title: 'black tea',
        quantity: '3',
    },
    {
        id: 4,
        title: 'green tea',
        quantity: '10',
    },
];

export const posts = [ 
    {  
        id: 1, 
        image: images.blog1,
        heading: `6 Tea Types: A Beginner's Guide`,
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '5th jan, 2020',
        adminImage: images.blogAdmin1,
        adminName: 'Thomas Kelly',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        tags: [ 
            {id: 1, title: 'healthy'},
            {id: 2, title: 'organic'},
            {id: 3, title: 'tea'},
        ],
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        comments: [
            {
                id: 1,
                image: images.commentsPic1,
                name: 'Billy Bates',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.commentsPic2,
                        name: 'Catherine Mowat',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.commentsPic3,
                name: 'Akash Chopra',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
        
    },
    {
        id: 2,
        image: images.blog3,
        heading: 'How to Drink green Tea Everyday',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '9th Apr, 2020',
        adminImage: images.blogAdmin3,
        adminName: 'Harry Boyle',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        tags: [ 
            {id: 1, title: 'healthy'},
            {id: 2, title: 'organic'},
            {id: 3, title: 'tea'},
        ],
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        }, 
        comments: [
            {
                id: 1,
                image: images.commentsPic1,
                name: 'Billy Bates',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.commentsPic2,
                        name: 'Catherine Mowat',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.commentsPic3,
                name: 'Akash Chopra',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    }, 
    {
        id: 3,
        image: images.blog5,
        heading: '5 Amazing Hibiscus tea Benefits',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '18th Sep, 2020',
        adminImage: images.blogAdmin5,
        adminName: 'Sandeep Patil',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        tags: [ 
            {id: 1, title: 'healthy'},
            {id: 2, title: 'organic'},
            {id: 3, title: 'tea'},
        ],
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        comments: [
            {
                id: 1,
                image: images.commentsPic1,
                name: 'javeed Raheem',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.commentsPic2,
                        name: 'Naina',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            },
            {
                id: 2,
                image: images.commentsPic3,
                name: 'Johny',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
];

export const tags = [
    {
        id: 1,
        title: 'chai tea',
    },
    {
        id: 2,
        title: 'herbal tea',
    },
    {
        id: 3,
        title: 'healthy',
    },
    {
        id: 4,
        title: 'organic', 
    },
    {
        id: 5,
        title: 'black tea', 
    },
    {
        id: 6,
        title: 'green tea',
    },
];