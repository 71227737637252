import React from 'react';
import './About.css';

import { Button } from '../../../components';
import { images } from '../../../constants';


 
const About = () => { 
  return ( 
    <section className='about'>

      <div class="box-container"> 

        <div class="image"> 
          <img src={images.about} alt="" />
        </div>

        <div class="content">
          <h3>Our tea blends are for everyone</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus qui ea ullam, enim tempora ipsum fuga alias quae ratione a officiis id temporibus autem? Quod nemo facilis cupiditate. Ex, vel?</p>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit amet enim quod veritatis, nihil voluptas culpa! Neque consectetur obcaecati sapiente?</p>
          <Button link={'/'} title={'learn more'} /> 
        </div>

      </div>

    </section>
  )
}

export default About;