import { images } from '../constants';

const home = [
    { 
        id: 1,
        image: images.home1,
        title: 'Tea is the elixir of life',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'Aroma like never before',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',   },
    { 
        id: 3,
        image: images.home3,
        title: 'The ultimate drink of relaxation',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',       
    },

];

export default home;