import { images } from '../constants';

const team = [
    {
        id: 1,
        Image: images.team1,
        name: 'William Scotton',
        title: 'Founder, Owner',
    },
    {
        id: 2,
        Image: images.team2,
        name: 'Alicia Walsh',
        title: 'Owner',
    },
    {
        id: 3,
        Image: images.team3,
        name: 'Billy Bates',
        title: 'Manager',
    },
    {
        id: 4,
        Image: images.team4,
        name: 'Olive Smith',
        title: 'Assistent Manager',
    },
 
];

export default team;