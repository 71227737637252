import React from 'react';
import './Team.css'; 

import { Heading } from '../../../components';
import { team } from '../../../constants';

import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Link } from "react-router-dom";


 
const Team = () => { 
  return (
      <> 
        <section className='team' id='team'>
    
            <Heading title={'our team'} span={'our team'} />

            <div className='box-container'> 
            {
                team.map((item) => { 
                return (
                    <div className='team-item' key={item.id}>
                        
                        <div className='image'>
                            <img src={item.Image} alt='Team Pic' />                            
                            <div class="icon-container">
                                <Link className='icon' to='/#'><FaFacebookF /></Link>
                                <Link className='icon' to='/#'><FaTwitter /></Link>
                                <Link className='icon' to='/#'><FaInstagram /></Link>
                                <Link className='icon' to='/#'><FaYoutube /></Link>
                            </div>  
                        </div>
                    
                        <div className='content'>
                            <h3>{item.name}</h3>
                            <p>{item.title}</p>
                        </div>

                    </div>
                )
                })
            }
            </div>

        </section>
    </>
  )
}

export default Team;