import React, { useEffect, useState } from 'react';
import './Menu.css'; 
 
import { herbal, chai, green, black } from '../../../constants'; 
import { PageTitle, MenuList } from '../../../components';


 
const Menu = () => {

  const [selected, setSelected] = useState("herbal"); 
  const [data, setData] = useState([]);

  const list = [
    {
      id: "herbal",
      title: "herbal tea",
    },
    {
      id: "chai",
      title: "chai tea",
    },
    {
      id: "green",
      title: "green tea",
    },
    {
      id: "black",
      title: "black tea",
    },
  ];

  useEffect(() => {
    switch (selected) {
      case "herbal":
        setData(herbal);
        break;
      case "chai":
        setData(chai);
        break;
      case "green":
        setData(green);
        break;
      case "black":
        setData(black);
        break;
      default:
        setData(herbal);
    }
  }, [selected]);

  return (
    <>
      <PageTitle title={'our menu'} page={'menu'} /> 
  
      <section className='menu'>

        <ul class="controls">
          { 
            list.map((item) => {
              return <MenuList
              id={item.id} 
              title={item.title}
              active={selected === item.id} 
              setSelected={setSelected} />
            })
          }
        </ul> 

        <div className='box-container'>
          {
            data.map((d) => {
              return (
                <div className='menu-item'>
                  <div className='image'>
                    <img src={d.image} alt='' />
                  </div>
                  <div className='content'>
                    <div className='intro'>
                      <h4>{d.name}</h4>
                      <span className='price'>${d.price}</span>
                    </div>
                    <p>{d.content}</p>
                  </div>
                </div>
              )
            })
          }
        </div>

      </section>
    </>
  )
}

export default Menu;