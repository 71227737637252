import React from 'react';
import './Faqs.css';

import { PageTitle, FaqItem } from '../../../components';
import { faqsContent } from '../../../constants';



const Faqs = () => { 
  
  return ( 
      <>
        <PageTitle title={'faqs'} page={'faqs'} />
        <section className='faq'>
            <div className='accordion-container'> 
            {
                faqsContent.map((faq) => {
                    return (
                        <FaqItem id={faq.id} title={faq.heading} content={faq.heading} />
                    )
                })
            }
            </div>
        </section>
      </>
    )

}

export default Faqs;