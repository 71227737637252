import React from 'react';
import './Profile.css';

import { Address, Button, Heading, PageTitle } from '../../../components';
import { profile } from '../../../constants';

 
  
const Profile = () => {
    const addressDetails = profile.address;
  return ( 
     <> 
        <PageTitle title={'profile'} page={'profile'} />
        <section class="profile">

            <div class="box-container">
                
                <div class="profile-item profile-details">
                    <Heading title={'profile details'} />
                    <div class="content">
                        <div className='image'>
                            <img src={profile.image} alt="" />
                        </div>
                        <div className='info'>
                            <div className='container'>
                                <div className='box'><span>name: </span><h3>{profile.name}</h3></div>
                                <div className='box'><span>Email: </span><p class="gmail">{profile.gmail}</p></div>
                                <div className='box'><span>Contact No: </span><p>{profile.contactNo}</p></div>
                                <Button link={'/edit-profile'} title={'edit profile'} />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="profile-item address-details">
                    <Heading title={'address details'} />
                    <div class="content">
                        <div class="address-container">
                            {
                                addressDetails.map((item) => {
                                    return(
                                        <Address 
                                        id={item.id}
                                        title={item.title}
                                        name={item.name}
                                        country={item.country}
                                        city={item.city}
                                        province={item.province}
                                        ZIP={item.ZIP}
                                        address={item.address} />
                                    )
                                })
                            }
                        </div> 
                        <Button link={'/edit-address'} title={'edit address'} />
                    </div>

                </div>
                
            </div>

        </section>
    </> 
  )
}

export default Profile;