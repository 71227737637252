import React from 'react';
import { PageTitle } from '../../../components';
import './Reservation.css';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Reservation = () => { 
  return ( 
    <> 
        <PageTitle title={'reservation'} page={'reservation'} />
        <section className='reservation'>
            <div className='box-container'>

                <div className='booking-info'>
                    <div className='item'>
                        <h3>working hours</h3>
                        <div className='timing'>
                            <h4>monday - friday</h4>
                            <p>8am - 6pm</p>
                        </div>
                        <div className='timing'>
                            <h4>saturday - sunday</h4>
                            <p>10am - 4pm</p>
                        </div> 
                    </div> 
                    <div className='item'>
                        <h3>restaurant address</h3>
                        <div className='address'>
                            <p>karachi, pakistan</p>
                        </div>
                    </div>
                    <div className='contact'>
                        <p>call us: 123-456-789</p>
                        <p>follow us: 
                            <span class="social">
                                <a href="/#"><FaFacebookF className='icon' /></a>
                                <a href="/#"><FaTwitter className='icon' /></a>
                                <a href="/#"><FaInstagram className='icon' /></a>
                                <a href="/#"><FaLinkedin className='icon' /></a>
                            </span>
                        </p>
                    </div>
                </div>
                
                <form method='post' className='booking-table'>

                    <h2>book your table</h2>
                    <div className='input-box'>
                        <input type="text" name="name" className='box' id="name" placeholder="name" required />
                        <input type="email" name="email" className='box' id="email" placeholder="email" required />
                    </div>
                    <div class="input-box">
                        <input type="number" name="number" className='box' id="number" placeholder="number" required />
                        <input type="date" name="date" className='box' id="date" required />
                    </div>
                    <div class="input-box">
                        <input type="time" name="time" className='box' id="time" required />
                        <input type="number" name="guests" className='box' id="guests" placeholder="guests" required />
                    </div>

                    <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="message"></textarea>
                    <button type="submit" className='btn' name="submit" id="submit">book now</button>
                    
                    <span className='alert' id="msg"></span>

                </form>
            </div>
        </section>
    </>
  )
}

export default Reservation;