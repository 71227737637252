import { images } from ".";

const profile = {
    image: images.profile,
    name: 'Mike Smith',
    gmail: 'abc@gmail.com',
    contactNo: '0123456789',
    address: [ 
        { 
            id: 1,
            title: 'shipping address',
            name: 'Mike Smith',
            country: 'pakistan',
            city: 'karachi',
            province: 'sindh',
            ZIP: '12345',
            address: '3rd Floor,Dawood Centre M.T.Khan Road',
        },
        {
            id: 2,
            title: 'billing address',
            name: 'Mike Smith',
            country: 'pakistan',
            city: 'karachi',
            province: 'sindh',
            ZIP: '12345',
            address: '3rd Floor,Dawood Centre M.T.Khan Road',
        },
    ],
};

export default profile;