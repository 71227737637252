import { images } from "../constants";

export const orderSummary = [ 
    {
        orderID: '#12345',
        delivery: 'jan 1st, 2021',
        items: [
            {id: 1, name: 'sub total', value: '$200.00'},
            {id: 2, name: 'delivery fee', value: '$50.00'},
            {id: 3, name: 'discount', value: '$20.00'},
            {id: 4, name: 'tax', value: '$20.00'},
            {id: 5, name: 'total', value: '$250.00'},
        ],
    },
];

export const address = [
    {
        id: 1,
        title: 'shipping address',
        name: 'Mike Smith',
        country: 'pakistan',
        city: 'karachi',
        province: 'sindh',
        ZIP: '12345',
        address: '3rd Floor,Dawood Centre M.T.Khan Road',
    },
    {
        id: 2,
        title: 'billing address',
        name: 'Mike Smith',
        country: 'pakistan',
        city: 'karachi',
        province: 'sindh',
        ZIP: '12345',
        address: '3rd Floor,Dawood Centre M.T.Khan Road',
    },
];

export const items = [
    {
        id: 1,
        image: images.product1,
        name: 'Chamomile Tea',
        price: '$50.00',
        quantity: '1',
        total: '$50.00',
    },
    {
        id: 2,
        image: images.product2,
        name: 'Peppermint Tea',
        price: '$100.00',
        quantity: '1',
        total: '$100.00',
    },
    {
        id: 3,
        image: images.product3,
        name: 'Ginger Tea',
        price: '$100.00',
        quantity: '2',
        total: '$200.00',
    },
    {
        id: 4,
        image: images.product4,
        name: 'Hibiscus Tea',
        price: '$150.00',
        quantity: '2',
        total: '$300.00',
    },
    {
        id: 5,
        image: images.product5,
        name: 'Rooibos Tea',
        price: '$150.00',
        quantity: '3',
        total: '$450.00',
    },
];
