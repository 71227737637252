import { images } from "../constants";

const relatedItems = [
    {
        id: 1,
        image: images.product2,
        name: 'Peppermint Tea',
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '150+',
        category: [
            'mix bouquets',
            'roses'
        ],
        tags: [
            'birthday',
            'roses',
            'wedding'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    }, 
    {
        id: 2,
        image: images.product3,
        name: 'Ginger Tea',
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '50+',
        category: [
            'mix bouquets',
            'roses'
        ],
        tags: [
            'birthday',
            'roses',
            'wedding'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 3,
        image: images.product4,
        name: 'Hibiscus Tea',
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '20+',
        category: [
            'mix bouquets',
            'roses'
        ],
        tags: [
            'birthday',
            'roses',
            'wedding'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 4,
        image: images.product5,
        name: 'Rooibos Tea',
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '30+',
        category: [
            'mix bouquets',
            'roses'
        ],
        tags: [
            'birthday',
            'roses',
            'wedding'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
];

export default relatedItems;