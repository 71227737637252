import React from 'react';
import './MenuList.css';
 


const MenuList = ({id, title, active, setSelected}) => {
  return ( 
    <li
        className={active ? "button active" : "button"}
        onClick={() => setSelected(id)}>
        {title}
    </li>
  )
}
  
export default MenuList; 