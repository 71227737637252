import React from 'react';
import './ProductFilterList.css';
 


const ProductFilterList = ({id, title, active, setSelected}) => {
  return (
    <li
        className={active ? "button active" : "button"}
        onClick={() => setSelected(id)}>
        <span>{title}</span>
    </li>
  )
} 

export default ProductFilterList;