import React from 'react';
import './ShopHeader.css';

import { FaList } from 'react-icons/fa';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';

import { Link } from 'react-router-dom';



const ShopHeader = ({showing, total}) => {  
  return ( 
    <div class="shop-header">

        <div class="showing">
            showing {showing} of {total} products 
        </div>

        <div class="styles">
            <Link to='/shop-grid'><BsFillGrid3X3GapFill /></Link>
            <Link to='/shop-list'><FaList /></Link>
        </div>

        <div class="sorting-type">
            <label for="sort">sort by:</label>

            <select name="sort" id="sort">
                <option value="default">default</option>
                <option value="name, A to Z">name, A to Z</option>
                <option value="mname, Z to A">name, Z to A</option>
                <option value="price, low to high">price, low to high</option>
                <option value="price, high to low">price, high to low</option>
            </select>
        </div>
        
    </div>
  )
}

export default ShopHeader;