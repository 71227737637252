import React from 'react';
import './AboutUs.css';

import { PageTitle } from '../../../components';
import { About, Team, Testimonials, Counter } from '../../../sections';



const AboutUs = () => {
  return (
    <>
      <PageTitle title={'about us'} page={'about'} />
      <section className='about-us'>
        <About />
        <Counter />
        <Team />
        <Testimonials />
      </section> 
    </>
  )
}

export default AboutUs;