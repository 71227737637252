import React from 'react';
import './Blog.css';

import { Link } from 'react-router-dom'; 


 
const Blog = (props) => { 
  const {image, heading, content, blog, adminImage, adminName, date} = props;
  return (  
    <div className='blog-item'>

        <div className='image'>
          <img src={image} alt='Blog' />
        </div>

        <div className='content'>
          <Link to={{
            pathname: '/Blogs/' + heading,
          }} 
          state= {blog}
          className='main-heading'>{heading}
          </Link>
          <p>{content}</p>    
          <div className='details'>
            <div className='admin'>
              <img src={adminImage} alt='' />
              <span>{adminName}</span>
            </div>
            <div className='date'>{date}</div>
          </div>
        </div>

    </div>
  )
}

export default Blog;