import React from 'react';
import './BlogInfo.css';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { AiOutlineCalendar } from 'react-icons/ai';



const BlogInfo = (props) => { 
         
    const {blog} = props;
    const paragraphs = blog.paragraph; 
 
    return(
    <div className='blog-info' key={blog.id}>
        <div className='image'>
            <img src={blog.image} alt='Blog' />
        </div>
        <div className='content'> 

            <div class="details"> 
                <h5 className='admin'>
                    <img src={blog.adminImage} alt='' />
                    <span>{blog.adminName}</span>
                </h5>
                <h5 class="date">
                    <AiOutlineCalendar className='icon' />
                    <span>{blog.date}</span>
                </h5>
            </div>

            <h3 class="main-heading">{blog.heading}</h3>

            <p>{paragraphs.para1}</p>  
            <p>{paragraphs.para2}</p> 
            
            <div class="important">
                {blog.important}
            </div>

            <p>{paragraphs.para3}</p>  
            <p>{paragraphs.para4}</p> 


            <div class="end-details">
                <div class="tags">
                    {
                        (blog.tags).map((item) => {
                            return <span>{item.title}</span>
                        })
                    }
                </div>

                <div class="share">
                    <h3>share:</h3>
                    <FaFacebookF className='icon' />
                    <FaLinkedin className='icon' />
                    <FaTwitter className='icon' />
                    <FaInstagram className='icon' />
                </div>
            </div>
        </div>
    </div>
    )
}

export default BlogInfo;