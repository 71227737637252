import { images } from '../constants';


export const categories = [ 
    {
        id: 1,
        title: 'herbal tea',
        quantity: '15',
    },
    {
        id: 2,
        title: 'chai tea',
        quantity: '12',
    }, 
    {
        id: 3, 
        title: 'black tea',
        quantity: '8',
    },
    {
        id: 4,
        title: 'green tea',
        quantity: '6',
    },
];
 
export const popular = [
    {
        id: 1,
        image: images.popularProduct1,
        name: 'matka chai',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 2,
        image: images.popularProduct2,
        name: 'Earl Gray Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 3,
        image: images.popularProduct3,
        name: 'Matcha Green Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
];

