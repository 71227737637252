import { images } from '.';

export const latest = [
    { 
        id: 1,
        image: images.latestProduct1, 
        name: 'Chamomile Tea',
        reviews: '100+', 
        price: '15.00', 
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 2,
        image: images.latestProduct2, 
        name: 'cinnamon chai',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 3,
        image: images.latestProduct3, 
        name: 'Chocolate Chai',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 4,
        image: images.latestProduct4, 
        name: 'Matcha Green Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 5,
        image: images.latestProduct5, 
        name: 'Peppermint Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 6,
        image: images.latestProduct6, 
        name: 'Cardamom Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        },
    },
    {
        id: 7,
        image: images.latestProduct7, 
        name: 'Masala Chai',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        },
    },
    {
        id: 8,
        image: images.latestProduct8, 
        name: 'Sencha Green Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        },
    },
];



export const featured = [
    {
        id: 1,
        image: images.featuredProduct1, 
        name: 'Hibiscus Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 2,
        image: images.featuredProduct2, 
        name: 'Chai Latte',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 3,
        image: images.featuredProduct3, 
        name: 'Yunnan Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 4,
        image: images.featuredProduct4, 
        name: 'Jasmine Green Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 5,
        image: images.featuredProduct5, 
        name: 'Ginger Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 6,
        image: images.featuredProduct6, 
        name: 'matka chai',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 7,
        image: images.featuredProduct7, 
        name: 'Earl Gray Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        },
    },
    {
        id: 8,
        image: images.featuredProduct8, 
        name: 'Shincha Green Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        },
    },
];



export const bestSeller = [
    {
        id: 1,
        image: images.bestSellerProduct1, 
        name: 'Sage Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 2,
        image: images.bestSellerProduct2, 
        name: 'Vanilla Chai',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 3,
        image: images.bestSellerProduct3, 
        name: 'Turkish Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 4,
        image: images.bestSellerProduct4, 
        name: 'Longjing Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 5,
        image: images.bestSellerProduct5, 
        name: 'Rose Hip Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 6,
        image: images.bestSellerProduct6, 
        name: 'Chai espresso',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 7,
        image: images.bestSellerProduct7, 
        name: 'English Breafast Tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        },
    },
    {
        id: 8,
        image: images.bestSellerProduct8, 
        name: 'Mint Green tea',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'herbal tea',
            'green tea'
        ], 
        tags: [
            'black tea',
            'healthy',
            'organic'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        },
    },
];