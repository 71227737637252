import React from 'react';
import './Comments.css'; 

import { BsReply, BsHeart } from 'react-icons/bs';


 

const Comments = (props) => {
    
    const {blog} = props;
    const blogComments = blog.comments;  
    
    return (
    <div className='comments'> 
        <h4>3 comments</h4> 

        <div className='box-container'>
        {
            blogComments.map((comment) => {
                const replyComments = comment.replies;
                return( 
                    <div className='item' key={comment.id}>

                        <div className='main comment'>
                            <div className='image'>
                                <img src={comment.image} alt='' />
                            </div>
                            <div className='content'>
                                <div className='intro'>
                                    <h6>{comment.name}</h6>
                                    <p>{comment.date}</p>
                                </div>
                                <p class="text">{comment.content}</p>
                                <div className='icon-container'>
                                    <div className='icon-item'><BsReply className='icon' /><span>reply</span></div>
                                    <div className='icon-item'><BsHeart className='icon' /><span>like</span></div>
                                </div>
                            </div>
                        </div>

                        {
                            replyComments.map((reply) => { 
                                return(
                                    <div className='reply comment' key={reply.id}>
                                        <div className='image'>
                                            <img src={reply.image} alt='' />
                                        </div>
                                        <div className='content'>
                                            <div className='intro'>
                                                <h6>{reply.name}</h6>
                                                <p>{reply.date}</p>
                                            </div>
                                            <p class="text">{reply.content}</p>
                                            <div className='icon-container'>
                                                <div className='icon-item'><BsReply className='icon' /><span>reply</span></div>
                                                <div className='icon-item'><BsHeart className='icon' /><span>like</span></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            })
        }


        </div>
    </div>
  )
}

export default Comments;