import React from 'react';
import {Home, About, Counter, Team, Testimonials, Products, Blogs} from '../../sections'; 

 
const HomePage = () => { 
  return ( 
      <div className='homepage'>
        <Home />
        <About />
        <Counter />
        <Products />
        <Team />
        <Testimonials />
        <Blogs />
      </div>
  )
}

export default HomePage;