import logo from '../assets/Logo/Tea-logo.png'; 

import home1 from '../assets/Home/Home-1.png';
import home2 from '../assets/Home/Home-2.png';
import home3 from '../assets/Home/Home-3.png'; 

import about from '../assets/About/About-Image.png';

import counter from '../assets/Counter/Counter.png';

import latestProduct1 from '../assets/Products-Filter/Latest/1.png';
import latestProduct2 from '../assets/Products-Filter/Latest/2.png';
import latestProduct3 from '../assets/Products-Filter/Latest/3.png';
import latestProduct4 from '../assets/Products-Filter/Latest/4.png';
import latestProduct5 from '../assets/Products-Filter/Latest/5.png';
import latestProduct6 from '../assets/Products-Filter/Latest/6.png';
import latestProduct7 from '../assets/Products-Filter/Latest/7.png';
import latestProduct8 from '../assets/Products-Filter/Latest/8.png'; 

import featuredProduct1 from '../assets/Products-Filter/Featured/1.png';
import featuredProduct2 from '../assets/Products-Filter/Featured/2.png';
import featuredProduct3 from '../assets/Products-Filter/Featured/3.png';
import featuredProduct4 from '../assets/Products-Filter/Featured/4.png';
import featuredProduct5 from '../assets/Products-Filter/Featured/5.png';
import featuredProduct6 from '../assets/Products-Filter/Featured/6.png';
import featuredProduct7 from '../assets/Products-Filter/Featured/7.png';
import featuredProduct8 from '../assets/Products-Filter/Featured/8.png';
 
import bestSellerProduct1 from '../assets/Products-Filter/Best-Sellers/1.png';
import bestSellerProduct2 from '../assets/Products-Filter/Best-Sellers/2.png';
import bestSellerProduct3 from '../assets/Products-Filter/Best-Sellers/3.png';
import bestSellerProduct4 from '../assets/Products-Filter/Best-Sellers/4.png';
import bestSellerProduct5 from '../assets/Products-Filter/Best-Sellers/5.png';
import bestSellerProduct6 from '../assets/Products-Filter/Best-Sellers/6.png';
import bestSellerProduct7 from '../assets/Products-Filter/Best-Sellers/7.png';
import bestSellerProduct8 from '../assets/Products-Filter/Best-Sellers/8.png';

import team1 from '../assets/Team/Team-1.png';
import team2 from '../assets/Team/Team-2.png';
import team3 from '../assets/Team/Team-3.png';
import team4 from '../assets/Team/Team-4.png';

import testimonial1 from '../assets/Testimonials/pic-1.png';
import testimonial2 from '../assets/Testimonials/pic-2.png';
import testimonial3 from '../assets/Testimonials/pic-3.png';
import testimonial4 from '../assets/Testimonials/pic-4.png';

import blog1 from '../assets/Blogs/Blog-1.png';
import blog2 from '../assets/Blogs/Blog-2.png';
import blog3 from '../assets/Blogs/Blog-3.png';
import blog4 from '../assets/Blogs/Blog-4.png';
import blog5 from '../assets/Blogs/Blog-5.png';
import blog6 from '../assets/Blogs/Blog-6.png';

import pageTitle from '../assets/PageTitle/pageTitle-bg.png';

import herbalMenu1 from '../assets/Menu/Herbal-Tea/1.png';
import herbalMenu2 from '../assets/Menu/Herbal-Tea/2.png';
import herbalMenu3 from '../assets/Menu/Herbal-Tea/3.png';
import herbalMenu4 from '../assets/Menu/Herbal-Tea/4.png';
import herbalMenu5 from '../assets/Menu/Herbal-Tea/5.png';
import herbalMenu6 from '../assets/Menu/Herbal-Tea/6.png';
import herbalMenu7 from '../assets/Menu/Herbal-Tea/7.png';
import herbalMenu8 from '../assets/Menu/Herbal-Tea/8.png'; 

import chaiMenu1 from '../assets/Menu/Chai-Tea/1.png';
import chaiMenu2 from '../assets/Menu/Chai-Tea/2.png';
import chaiMenu3 from '../assets/Menu/Chai-Tea/3.png';
import chaiMenu4 from '../assets/Menu/Chai-Tea/4.png';
import chaiMenu5 from '../assets/Menu/Chai-Tea/5.png';
import chaiMenu6 from '../assets/Menu/Chai-Tea/6.png';
import chaiMenu7 from '../assets/Menu/Chai-Tea/7.png';
import chaiMenu8 from '../assets/Menu/Chai-Tea/8.png'; 

import blackMenu1 from '../assets/Menu/Black-Tea/1.png';
import blackMenu2 from '../assets/Menu/Black-Tea/2.png';
import blackMenu3 from '../assets/Menu/Black-Tea/3.png';
import blackMenu4 from '../assets/Menu/Black-Tea/4.png';
import blackMenu5 from '../assets/Menu/Black-Tea/5.png';
import blackMenu6 from '../assets/Menu/Black-Tea/6.png';
import blackMenu7 from '../assets/Menu/Black-Tea/7.png';
import blackMenu8 from '../assets/Menu/Black-Tea/8.png'; 

import greenMenu1 from '../assets/Menu/Green-Tea/1.png';
import greenMenu2 from '../assets/Menu/Green-Tea/2.png';
import greenMenu3 from '../assets/Menu/Green-Tea/3.png';
import greenMenu4 from '../assets/Menu/Green-Tea/4.png';
import greenMenu5 from '../assets/Menu/Green-Tea/5.png';
import greenMenu6 from '../assets/Menu/Green-Tea/6.png';
import greenMenu7 from '../assets/Menu/Green-Tea/7.png';
import greenMenu8 from '../assets/Menu/Green-Tea/8.png'; 

import blogAdmin1 from '../assets/Blog-Admin/pic-1.png';
import blogAdmin2 from '../assets/Blog-Admin/pic-2.png';
import blogAdmin3 from '../assets/Blog-Admin/pic-3.png';
import blogAdmin4 from '../assets/Blog-Admin/pic-4.png';
import blogAdmin5 from '../assets/Blog-Admin/pic-5.png';
import blogAdmin6 from '../assets/Blog-Admin/pic-6.png';

import post1 from '../assets/Recent-Posts/Blog-1.png';
import post2 from '../assets/Recent-Posts/Blog-2.png';
import post3 from '../assets/Recent-Posts/Blog-3.png';

import commentsPic1 from '../assets/Comments/pic-1.png';
import commentsPic2 from '../assets/Comments/pic-2.png';
import commentsPic3 from '../assets/Comments/pic-3.png';

import gallery1 from '../assets/Gallery/Gallery-1.png';
import gallery2 from '../assets/Gallery/Gallery-2.png';
import gallery3 from '../assets/Gallery/Gallery-3.png';
import gallery4 from '../assets/Gallery/Gallery-4.png';
import gallery5 from '../assets/Gallery/Gallery-5.png';
import gallery6 from '../assets/Gallery/Gallery-6.png';
import gallery7 from '../assets/Gallery/Gallery-7.png';
import gallery8 from '../assets/Gallery/Gallery-8.png';
import gallery9 from '../assets/Gallery/Gallery-9.png';

import profile from '../assets/Profile/Profile-Pic.png';

import popularProduct1 from '../assets/Popular/1.png';
import popularProduct2 from '../assets/Popular/2.png';
import popularProduct3 from '../assets/Popular/3.png';

import product1 from '../assets/Products/Product-1.png';
import product2 from '../assets/Products/Product-2.png';
import product3 from '../assets/Products/Product-3.png';
import product4 from '../assets/Products/Product-4.png';
import product5 from '../assets/Products/Product-5.png';
import product6 from '../assets/Products/Product-6.png';
import product7 from '../assets/Products/Product-7.png';
import product8 from '../assets/Products/Product-8.png'; 
import product9 from '../assets/Products/Product-9.png';

import productGallery1 from '../assets/Product-Gallery/1.png';
import productGallery2 from '../assets/Product-Gallery/2.png';
import productGallery3 from '../assets/Product-Gallery/3.png';
import productGallery4 from '../assets/Product-Gallery/4.png';











const Images = {
    logo,

    home1,
    home2,
    home3,

    about,

    counter,

    latestProduct1,
    latestProduct2,
    latestProduct3,
    latestProduct4,
    latestProduct5,
    latestProduct6,
    latestProduct7,
    latestProduct8,

    featuredProduct1,
    featuredProduct2,
    featuredProduct3,
    featuredProduct4,
    featuredProduct5,
    featuredProduct6,
    featuredProduct7,
    featuredProduct8,

    bestSellerProduct1,
    bestSellerProduct2,
    bestSellerProduct3,
    bestSellerProduct4,
    bestSellerProduct5,
    bestSellerProduct6,
    bestSellerProduct7,
    bestSellerProduct8,

    team1,
    team2,
    team3,
    team4,

    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,

    blog1,
    blog2,
    blog3,
    blog4,
    blog5,
    blog6,

    pageTitle,

    herbalMenu1,
    herbalMenu2,
    herbalMenu3,
    herbalMenu4,
    herbalMenu5,
    herbalMenu6,
    herbalMenu7,
    herbalMenu8,

    chaiMenu1,
    chaiMenu2,
    chaiMenu3,
    chaiMenu4,
    chaiMenu5,
    chaiMenu6,
    chaiMenu7,
    chaiMenu8,

    blackMenu1,
    blackMenu2,
    blackMenu3,
    blackMenu4,
    blackMenu5,
    blackMenu6,
    blackMenu7,
    blackMenu8,

    greenMenu1,
    greenMenu2,
    greenMenu3,
    greenMenu4,
    greenMenu5,
    greenMenu6,
    greenMenu7,
    greenMenu8,

    blogAdmin1,
    blogAdmin2,
    blogAdmin3,
    blogAdmin4,
    blogAdmin5,
    blogAdmin6,

    post1,
    post2,
    post3,

    commentsPic1,
    commentsPic2,
    commentsPic3,

    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,

    profile,

    productGallery1,
    productGallery2,
    productGallery3,
    productGallery4,

    popularProduct1,
    popularProduct2,
    popularProduct3,

    product1,
    product2,
    product3,
    product4,
    product5,
    product6,
    product7,
    product8,
    product9,
};

export default Images;