import React from 'react';
import './ShopList.css';

import { PageTitle, ShopSidebar, ShopHeader, Product, PagesNo } from '../../../components';
import { productsContent } from '../../../constants';
 


const ShopList = () => {  

  const size = 4;
  const products = productsContent.slice(0, size);
  
  return ( 
    <> 
      <PageTitle title={'shop list'} page={'shop list'} />
        <section className='shop'>
            <ShopSidebar />
            <div className='shop-container'>
                <ShopHeader showing='4' total='15' /> 
                <div className='product-container list'>
                {
                  products.map((product) => { 
                    return(
                      <Product  
                      key={product.id} 
                      image={product.image}
                      name={product.name}
                      content={product.content}
                      disprice={product.disprice}
                      price={product.price}
                      product={product} />
                    )
                  })
                }
               </div>
                <PagesNo />
            </div>
        </section>
    </>
  )
}

export default ShopList;