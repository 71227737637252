import React, { useEffect, useState } from 'react';
import './Products.css'; 

import { latest, featured, bestSeller } from '../../../constants'; 
import { Heading, Product, ProductFilterList } from '../../../components';



const Products = () => { 

  const [selected, setSelected] = useState("latest");
  const [data, setData] = useState([]);

  const list = [
    {
      id: "latest",
      title: "latest",
    },
    {
      id: "featured",
      title: "featured",
    },
    {
      id: "bestSeller",
      title: "best Seller",
    },
  ];

  useEffect(() => {
    switch (selected) {
      case "latest":
        setData(latest);
        break;
      case "featured":
        setData(featured);
        break;
      case "bestSeller": 
        setData(bestSeller);
        break;
      default:
        setData(latest);
    }
  }, [selected]);

  return (
  <section className='products'>

    <Heading title={'our products'} span={'what we offer'} />

    <ul class="controls">
      {
        list.map((item) => {
          return <ProductFilterList
          id={item.id} 
          title={item.title}
          active={selected === item.id} 
          setSelected={setSelected} />
        })
      }
    </ul>

    <div className='box-container'>
      {
        data.map((d) => {
          return (
            <Product  
            key={d.id}  
            image={d.image}
            name={d.name}
            disprice={d.disprice}
            price={d.price}
            product={d} />
          )
        })
      }
    </div>

  </section>
  )
}

export default Products;