import React from 'react';
import './OrderDetails.css';

import {  PageTitle, Heading, ShopSummary, Address, OrderItem } from '../../../components';
import { orderSummary, address, items } from '../../../constants';


const OrderDetails = () => {
  return (   
    <>
      <PageTitle title={'order details'} page={'order details'} />
      <section className='order-details'>

        <div className='order-items'>

          <div className='container'>
            <Heading title='order items' />

            <div className='title'>
              <h3>product</h3>
              <h3>name</h3>
              <h3>price</h3>
              <h3>quantity</h3>
              <h3>total</h3>
            </div>

            <div className='box-container'> 
              {
                items.map((item) => {                 
                  return(
                    <OrderItem 
                    id={item.id}
                    image={item.image}
                    name={item.name}
                    price={item.price}
                    quantity={item.quantity}
                    total={item.total}
                    />
                  )              
                })
              }
            </div>
          </div>

        </div>

        
        <div className='order-intro'>

          <div className='address-details'>
            <Heading title='address details' />
            <div className='box-container'>
              {
                address.map((item) => {
                  return(
                    <Address 
                    id={item.id}
                    title={item.title}
                    name={item.name}
                    country={item.country}
                    city={item.city}
                    province={item.province}
                    ZIP={item.ZIP}
                    address={item.address} />
                  ) 
                })
              }
            </div>
          </div>

          <div className='order-summary'>
            { 
              orderSummary.map((item) => {
                const summary = item.items; 
                return( 
                  <>
                    <Heading title='order summary' />
                    <div className='intro'>
                      <div className='order-id'>{item.orderID}</div>
                      <div className='delivery'>{item.delivery}</div>
                    </div>
                    <div className='list'>
                      <ShopSummary summary={summary} />
                    </div>
                  </>
                )

              })
            }

          </div>

        </div>

      </section>
    </>
  )
}

export default OrderDetails