import React from 'react';
import './GalleryItem.css';

import { BsSearch} from 'react-icons/bs';
import { Link } from 'react-router-dom'; 

 
const GalleryItem = ({id, image, title, category, link, index, popup}) => {
  
  return (
 
      <div className='gallery-item' key={id}>
          <img src={image} alt='gallery image' /> 
          <div className='content'>
            <h4>{title}</h4>
            <p>{category}</p>
            <Link to={link} onClick={() => {popup(index)}}><BsSearch /></Link>
          </div>
      </div> 

  )
}

export default GalleryItem;